import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { verifyToken } from '../Common/AuthToken';

const FileTransfer = () => {
  const [modal, setModal] = useState(false);
  const [source, setSource] = useState('');
  const [destination, setDestination] = useState('');
  const [action, setAction] = useState('');
  const [error, setError] = useState('');
  const [userData, setUserData] = useState([]);
  const [cachedData, setCachedData] = useState([]);
  const storedToken = localStorage.getItem('auth_token');

  const toggleModal = () => setModal(!modal);

  const updateCache = async () => {
    try {
      const config = {
        method: 'POST',
        url: '/api/allProjects',
        headers: { 'Content-Type': 'application/json' },
        data: { user_email: userData[0]?.email || "" },
      };

      const response = await axios.request(config);
      const projectOptions = response.data.data.map((project) => (
        <option key={project.uniqueId.toLowerCase()} value={project.uniqueId.toLowerCase()}>
          {project.projectName}
        </option>
      ));
      setCachedData([
        <option key="" value="">
          Select a Project
        </option>,
        ...projectOptions,
      ]);
    } catch (error) {
      console.error('Error updating cache:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = { token: storedToken };
        const tokenResponse = await verifyToken(user);
        setUserData([tokenResponse?.data]);
      } catch (error) {
        console.error('Error verifying token:', error);
        toast.error('Error fetching user data. Please try again.');
      }
    };

    fetchData();
  }, [storedToken]);

  useEffect(() => {
    if (userData.length) {
      updateCache();
    }
  }, [userData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!source || !destination || !action) {
      setError('Please fill in all fields.');
      return;
    }

    setError('');

    // Close the modal immediately
    setModal(false);

    try {
      const config = {
        method: 'POST',
        url: '/api/newRcloneProcess',
        headers: { 'Content-Type': 'application/json' },
        data: {
          source,
          destination,
          action: action.toLowerCase(),
          user_email: userData[0]?.email || "",
        },
      };

      const response = await axios.request(config);
      const uniqueId = response.data.uniqueId;
      setTimeout(() => {
        toast.success(
          `Your request with process ID: ${uniqueId} has been completed. Go to User Management tab to see the process details.`
        );
      }, 5000);
    } catch (error) {
      console.error('Error submitting file transfer process:', error);
      toast.error('Failed to start the process. Please try again.');
    }
  };

  return (
    <div>
      <h5 className="fw-bold mb-3">File Transfer</h5>
      <Button color="primary" onClick={toggleModal}>
        Start File Transfer
      </Button>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Start File Transfer</ModalHeader>
        <ModalBody>
          {error && <div className="alert alert-danger">{error}</div>}
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="source">Source</Label>
              <Input
                type="text"
                id="source"
                placeholder="Enter source path"
                value={source}
                onChange={(e) => setSource(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="destination">Destination</Label>
              <Input
                type="select"
                id="destination"
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
              >
                {cachedData}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="action">Action</Label>
              <Input
                type="select"
                id="action"
                value={action}
                onChange={(e) => setAction(e.target.value)}
              >
                <option value="">Select an Action</option>
                <option value="Copy">Copy</option>
                <option value="Move">Move</option>
                <option value="Sync">Sync</option>
              </Input>
            </FormGroup>
            <ModalFooter>
              <Button color="primary" type="submit">
                Submit
              </Button>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>

      <ToastContainer autoClose={3000} limit={1} draggable pauseOnHover />
    </div>
  );
};

export default FileTransfer;
